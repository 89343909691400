<template>
  <section class="s-help">
    <div class="s-help-container">
      <div class="s-help-column">
        <h2 class="f-hm">
          Need
          <br />
          <em>Help?</em>
        </h2>
      </div>
      <div class="s-help-column">
        <p class="f-pr">
          <strong>Where to buy?</strong>
        </p>
        <p class="f-pr">Interested? Find the nearest store to me.</p>
        <!-- <a
          class="f-bs"
          href="https://store.fashionunited.com/nl/nl/locators/rino-pelle"
          target="_blank"
          rel="noopener"
        >
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 1; transform: translate(0px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 0; transform: translate(-36.5948px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <span class="f-bs-line" style="transform: translate(0px, 0px);"></span>
          <i class="f-bs-label" style="transform: translate(0px, 0px);">Productlocator</i>
        </a> -->
      </div>
      <div class="s-help-column">
        <p class="f-pr">
          <strong>Become a Member</strong>
        </p>
        <p class="f-pr">Already a member? Login to discover our collections.</p>
        <a class="f-bs popup"  @click="openSigninPop()"  rel="noopener" v-if="!userInfo.id">
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 1; transform: translate(0px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 0; transform: translate(-36.5948px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <span class="f-bs-line" style="transform: translate(0px, 0px);"></span>
          <i class="f-bs-label" style="transform: translate(0px, 0px);">Login</i>
        </a>
        <a class="f-bs popup"  href="#/page/myaccount"  rel="noopener" v-if="userInfo.id">
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 1; transform: translate(0px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 0; transform: translate(-36.5948px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <span class="f-bs-line" style="transform: translate(0px, 0px);"></span>
          <i class="f-bs-label" style="transform: translate(0px, 0px);">My Account</i>
        </a>
      </div>
      <div class="s-help-column">
        <p class="f-pr">
          <strong>Need help?</strong>
        </p>
        <p class="f-pr">Haven’t found what you’re looking for? Contact us.</p>
        <a class="f-bs" href="#/contact">
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 1; transform: translate(0px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <svg
            class="f-bs-arrow"
            viewBox="0 0 6 10"
            style="opacity: 0; transform: translate(-36.5948px, 0px);"
          >
            <path
              d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
            />
          </svg>
          <span class="f-bs-line" style="transform: translate(0px, 0px);"></span>
          <i class="f-bs-label" style="transform: translate(0px, 0px);">Contact us</i>
        </a>
      </div>
    </div>
  </section>
</template>

<style scoped>
.s-help{
  z-index: 6 !important;
}


.f-bs:hover .f-bs-arrow,
.f-bs:hover .f-bs-line{
    animation: right-left .5s ease-in-out forwards;
}
.f-bs:hover .f-bs-label{
    animation: left-right .5s ease-in-out forwards;
}

@keyframes right-left{
  0%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(90px, 0px);
  }
}
@keyframes left-right{
  0%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(-50px, 0px);
  }
}

.popup:hover .f-bs-arrow,
.popup:hover .f-bs-line{
    animation: animate-right-left .5s ease-in-out forwards;
}
.popup:hover .f-bs-label{
    animation: animate-left-right .5s ease-in-out forwards;
}

@keyframes animate-right-left{
  0%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(45px, 0px);
  }
}
@keyframes animate-left-right{
  0%{
    transform: translate(0px, 0px);
  }
  100%{
    transform: translate(-45px, 0px);
  }
}
</style>


<script>
export default {
  components: {},
  data(){
    return {
        data: {},
        userInfo:{},
        product: {
            product_quantity: 1
        },
        fact: 3
    }
  },
    watch:{
    },

  created(){
    this.userInfo = this.$store.state.customer
  },
  methods: {
    openSigninPop(){
        document.getElementById('signinContainer').classList.add('showSigninContainer'); 
    },
  },
}
</script>